import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Body, Container, Divider, Header, TextCont } from './styles';
// import Chevron from '../../../images/chevron.svg';

function ConsumerSideBySide(props) {
    const { img, alt, header, body } = props;
    // console.log(img);
    const containerRef = useRef();
    // const initialLoadRan = useRef(false);
    // useEffect(() => {
    //     if (!containerRef.current) {
    //         return;
    //     }
    //     const to = setTimeout(() => {
    //         if (!containerRef.current) {
    //             return;
    //         }
    //         containerRef.current.style.opacity = 1
    //         initialLoadRan.current = true;
    //     }, 300);
    //     return () => {
    //         clearTimeout(to);
    //     }
    // }, [containerRef]);

    // useEffect(() => {
    //     if ((props.progress < 0.1 || props.progress > 0.9) && !props.disableFadeOut) {
    //         containerRef.current.style.opacity = 0;
    //     } else if (initialLoadRan.current) {
    //         containerRef.current.style.opacity = 1
    //     }
    // }, [props.progress]);


    return (
        // <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>

        <Container ref={(ref) => containerRef.current = ref}>
            
                <TextCont>
                    <Header>{header}</Header>
                    <Body>{body}</Body>
                </TextCont>
                <Divider />
                <Img
                    fixed={img}
                    alt={alt}
                // loading="eager"
                // fadeIn={false}
                />
            {/* {!props.disableFadeOut && <Chevron style={{position: 'absolute', zIndex: '999', left: '50%', bottom: '25px', transform: 'translateX(-50%)', opacity: 0.25}}/>} */}
        </Container>

        // </div>
    )
}

ConsumerSideBySide.propTypes = {
    img: PropTypes.any,
    alt: PropTypes.string,
    header: PropTypes.string,
    body: PropTypes.string,
    progress: PropTypes.number,
    disableFadeOut: PropTypes.bool,
}

export default ConsumerSideBySide;