import React from "react"
import LandingPageMain from "../components/Main/LandingPage"
import { graphql } from "gatsby"
import PropTypes from 'prop-types';

class Landing extends React.Component {
  render() {
    return (
      <LandingPageMain images={this.props.data} />
    );
  }
}

Landing.propTypes = {
  data: PropTypes.any
}


export default Landing;

export const pageQuery = graphql`
query {
  header: file(relativePath: { eq: "cover.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 2400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  bins: file(relativePath: { eq: "bins.png" }) {
    childImageSharp {
      fixed (width: 514){
        ...GatsbyImageSharpFixed
      }
      
    }
  }
  phone: file(relativePath: { eq: "phone.png" }) {
    childImageSharp {
      fixed (width: 514){
        ...GatsbyImageSharpFixed
      }
      
    }
  }
  forklift: file(relativePath: { eq: "forklift.png" }) {
    childImageSharp {
      fixed (width: 514){
        ...GatsbyImageSharpFixed
      }
      
    }
  }
}
`