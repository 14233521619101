import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Header, TitleImage, Footer } from '../../ui';
// import { Stickyroll } from '@stickyroll/stickyroll';
import ConsumerSideBySide from '../../ui/ConsumerSideBySide';
import Warning from '../../ui/Warning';
// import ScrollSnap from 'scroll-snap'

// const snapConfig = {
//    /**
//    * snap-destination for x and y axes
//    * should be a valid css value expressed as px|%|vw|vh
//    */
//     snapDestinationX: '0%',
//     snapDestinationY: '90%',
//     /**
//      * time in ms after which scrolling is considered finished
//      * [default: 100]
//      */
//     timeout: 100,
//     /**
//      * duration in ms for the smooth snap
//      * [default: 300]
//      */
//     duration: 300,
//     /**
//      * threshold to reach before scrolling to next/prev element, expressed as a percentage in the range [0, 1]
//      * [default: 0.2]
//      */
//     threshold: 0.2,
//     /**
//      * when true, the scroll container is not allowed to "pass over" the other snap positions
//      * [default: false]
//      */
//     snapStop: false,
//     /**
//      * custom easing function
//      * [default: easeInOutQuad]
//      * for reference: https://gist.github.com/gre/1650294
//      * @param t normalized time typically in the range [0, 1]
//      */
//     easing: easeInOutQuad,
// }

// import { WhatIsIt, HowItWorks, HumanAssisted } from './components';

const ScrollableTitle = (props) => {
  const contRef = useRef();
  const [isMobile, setIsMobile] = useState(false);
  // useEffect(() => {
  //   if (!contRef.current || !contRef.current.style) {
  //     console.log('no ref');
  //     return;
  //   }
  //   setTimeout(() => {
  //     if (!contRef.current || !contRef.current.style) {
  //       console.log('no ref');
  //       return;
  //     }
  //     contRef.current.style.opacity = 1
  //   }, 500);
  // }, [contRef]);
  useEffect(() => {
    if (typeof window == undefined) return;
    if (window.innerWidth < 500 && !isMobile) {
      setIsMobile(true);
    }
    if (window.innerWidth > 500 & isMobile) {
      setIsMobile(false);
    }
    const listener = window.addEventListener('resize', () => {
      if (window.innerWidth < 500 && !isMobile) {
        setIsMobile(true);
      }
      if (window.innerWidth > 500 && isMobile) {
        setIsMobile(false);
      }
    })
    return () => {
      window.removeEventListener('resize', listener);
    }
  }, []);
  return (<div style={{ transition: 'opacity 1s', width: '100%', height: `${isMobile ? 40 : 100}vh`, scrollSnapAlign: 'start' }} ref={(ref) => contRef.current = ref}>
    {props.children}
  </div>)
}

ScrollableTitle.propTypes = {
  children: PropTypes.any,
}

const LandingPageMain = props => {

  const images = props.images;
  const container = useRef();
  const [warningPadding, setWarningPadding] = useState(0);
  // const currSlide = useRef(0);
  // const scrolling = useRef(false);
  // console.log(images);
  useEffect(() => {
    if (typeof window === undefined) return;
    if (window.innerWidth < 1000 && warningPadding != 0) {
      setWarningPadding(0);
    } 
    if (window.innerWidth > 1000 && warningPadding != 20) {
      setWarningPadding(20);
    }
    const listener = window.addEventListener('resize', () => {
      if (window.innerWidth < 1000 && warningPadding == 20) {
        setWarningPadding(0);
      } 
      if (window.innerWidth > 1000 && warningPadding == 0) {
        setWarningPadding(20);
      }
    })
    return () => {
      if (typeof window === undefined) return;
      window.removeEventListener('resize', listener);
    }
  }, [warningPadding]);
  const content = [
    {},
    { header: 'Leave your chores to us', alt: 'image of bins with dishes in them', image: images.bins.childImageSharp.fixed, body: 'You’ll never have to clean your dishes, do your laundry, or take out your garbage. Our housekeeping robot will do that for you.' },
    { header: 'Let your apartment work for you.', mobileLeftOffset: 41, alt: 'rendering of phone displaying an app', image: images.phone.childImageSharp.fixed, body: 'Set and forget your desired chore routine right on our app. From here you’ll be able to pay rent and do everything else apartment related.',},
    { header: 'Grow with maslo 🌱', mobileLeftOffset: 18, alt: 'rendering of robot with forklift', image: images.forklift.childImageSharp.fixed, body: 'This is just the begining. As our robots get smarter and more able so does your apartment. Whether it’s package delivery, extra storage, or more, we can’t wait for you to try it.' }
  ]

  // const warning = '🚨[WORK IN PROGRESS]🚨 -- THIS WEBSITE IS SPECULATIVE AND FOR INVESTORS OR POTENTIAL PARTNERS -- 🤔 --INTERESTED ? -- 🤔 -- WANT TO KNOW MORE? FEEL FREE TO DROP US A LINE @ 412-996-1311 --  🚨[CONFIDENTIAL]🚨';

  return (
    <div style={{ margin: 'auto', paddingLeft: `${warningPadding}px`, scrollSnapType: 'y mandatory' }} ref={(ref) => container.current = ref} >
      <Warning />

      {content.map((_, pageIndex) => {
        if (pageIndex === 0) {
          return (
            <ScrollableTitle key={pageIndex}>
              <Header />
              <TitleImage imageSrc={images.header.childImageSharp.fluid} alt="Robot in apartment" loading="eager" />
            </ScrollableTitle>
          )
        }
        else {
          const currPage = content[pageIndex]
          return (
            <ConsumerSideBySide disableFadeOut={pageIndex === content.length - 1} key={pageIndex} alt={currPage.alt} header={currPage.header} img={currPage.image} body={currPage.body} />
          );
        }
      })}

      {/* <DropDownable title="What is it?" defaultState={true}>
        <WhatIsIt botsImage={props.images.botPair.childImageSharp.fixed}
          apartmentImage={props.images.apartment.childImageSharp.fixed} />
      </DropDownable>
      <DropDownable title="How it works:" defaultState={true}>
      <HowItWorks collection={props.images.collection.childImageSharp.fixed}
                  accumulation={props.images.accumulation.childImageSharp.fixed}
                  replacement={props.images.replacement.childImageSharp.fixed}/>
      </DropDownable> 
      <DropDownable title="Human Assisted:" defaultState={true}>
        <HumanAssisted carrier={props.images.carrier.childImageSharp.fixed}
          contract={props.images.contract.childImageSharp.fixed}/>
      </DropDownable> */}
      <Footer />
    </div>
  )
}

LandingPageMain.propTypes = {
  images: PropTypes.any
}

export default LandingPageMain;
