import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 1s;
    height: 100vh;
    scroll-snap-align: start;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
    }
`

export const Divider = styled.div`
    width: 3px;
    height: 125px;
    background: #e0e0e0;
    // margin-right: 80px;
    @media screen and (max-width: 1000px) {
        display: none;
    }
`

export const Header = styled.p`
font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
width: 429px;
// margin-right: 80px;
margin-bottom: 0px;

@media screen and (max-width: 1000px) {
    padding-left: 0px;
    width: 371px;
}
@media screen and (max-width: 400px) {
    width: 300px;
}
/* Gray 1 */

color: #333333;
padding-left: 80px;
`

export const Body = styled.p`
font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: 16px;
padding-left: 80px;
margin-top: 0px;
width: 371px;
@media screen and (max-width: 400px) {
    width: 300px;
}
@media screen and (max-width: 1000px) {
    margin-bottom: 40px;
    padding-left: 0px;
}
color: rgba(0, 0, 0, 0.8);
`
export const TextCont = styled.div `
    width: 514px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 1000px) {
        align-items: center;
    }
`
